<form [formGroup]="formGroup" autocomplete="off">
  <div class="form-row">
    <div class="col-sm-12">
      <h1>{{getCurrentSection()?.title}}</h1>
      <h2 class="hide-for-desktop" *ngIf="!checking">{{getCurrentSectionStep()}}</h2>
      <hr/>
    </div>
  </div>
  <div class="row" *ngIf="getCurrentSection()?.introduction">
    <div class="col-sm-10">
      <div [innerHtml]="getCurrentSection()?.introduction"></div>
    </div>
  </div>
  <div class="row" *ngIf="getCurrentSection()?.type === 'ADDITIONAL' && getVirusWarning().length > 0">
    <div class="col-sm-10 alert alert-danger">
      {{getVirusWarning()}}
    </div>
  </div>

  <fieldset [disabled]="checking">
    <ng-container *ngFor="let row of rows">
      <div class="form-row" [ngClass]="{'hiderow':hideRow(row)}"
           *ngIf="showRow(row)">
        <ng-container *ngFor="let field of row.fields">
          <div [class]="'form-group col-sm-'+ field.nrColumns" *ngIf="!field.conditionalDisabled && field.enabled">
            <app-dynamic-formfield [field]="field" [form]="formGroup"
                                   [keyupFn]="field.callbackFn"
                                   [changeFn]="field.changeFn"
                                   [uploader]="field.uploader"
                                   [signUp]="signUp"
                                   [autoCompletor]="field.autoCompletor"
                                   [checking]="checking"
                                   (changedField)="fieldChanged($event)"
                                   [isSubmitted]="isSubmitted"
                                   (uploadedFile)="uploadDocument($event)"
                                   (uploadFileInProgress)="uploadInProgress($event)"
                                   [documentsInProgressName]="documentsInProgressName"
            ></app-dynamic-formfield>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </fieldset>

  <div class="row">
    <div class="col-sm-10">
      <div class="alert alert-warning" role="alert" *ngIf="isSubmitted && formGroup.invalid">
        Nog niet alle verplichte velden zijn ingevuld.
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="flex-navigation" *ngIf="!checking; else checkButton">
      <span (click)="back()" class="nav-button-prev"><i class="fas fa-angle-left"></i></span>
      <span
        (click)="save()"
        [class]="!btnClickAllowed ? 'span-disabled nav-button-next' : 'nav-button-next'"
        [innerHTML]="btnTextValue"></span>
    </div>
    <ng-template #checkButton>
      <div class="col-sm-12">
        <button type="button" class="float-right btn btn-secondary btn-outline-rounded" (click)="goToHere()">
          Bewerken
        </button>
      </div>
    </ng-template>
  </div>
</form>
