import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormGroup, ValidationErrors} from "@angular/forms";
import {FormField} from "../../model/formField";
import {finalize, Subscription} from "rxjs";
import {FileService} from "../../services/file.service";
import {SignUp, UploadedDocument} from "../../model/signup";
import {UploadedFileTypeVarName} from "../../model/sentfile";
import {HttpEventType, HttpResponse} from "@angular/common/http";


@Component({
  selector: 'app-uploader-component',
  templateUrl: './app-uploader.component.html',
  styleUrls: []
})
export class UploaderComponent implements OnInit {

  @Input() showError: ValidationErrors;
  @Input() field!: FormField;
  @Input() form!: FormGroup;
  @Input() acceptedFiles: string;
  @Input() signUp: SignUp;
  @Input() checking: boolean = false;
  @Output() uploadedFile = new EventEmitter<any>();
  @Output() uploadFileInProgress = new EventEmitter<string[]>();

  documentsInProgress: any[] = [];
  @Input() documentsInProgressName: string[];
  documents: UploadedDocument[];

  uploadProgress:number;
  uploadSub: Subscription;

  ngOnInit() {
    this.documents = JSON.parse(JSON.stringify(this.signUp.additionalInformation.documents));
  }

  constructor(private fileService: FileService) {}

  public onUpload($event): void {
    this.onChange($event, false) //TODO validation ook automatisch meegeven
  }

  public getFiles() {
    return this.documents.filter((item) => UploadedFileTypeVarName[this.field.varName.split('\.')[0]] == item.type);
  }

  onChange(event, validation: boolean) { //TODO hier ook varname gebruiken in documentsInProgressName

    const files: File[] = Array.from(event.target.files);
    this.documentsInProgressName.push(...files.map((item) => item.name));
    this.uploadFileInProgress.emit(this.documentsInProgressName);
    this.documentsInProgress = files;

    files.forEach(file => {
      if (file) {
        event.target.value = '';

        let uploadedFile = {
          "type": UploadedFileTypeVarName[this.field.varName.split('\.')[0]],
          "fileName": '',
          "originalFileName": file.name,
          "varName": this.field.varName,
          "malwareDetected": false,
          "malwareName": ""
        }

        let formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('uploadedFile', JSON.stringify(uploadedFile));

        const upload$ = this.fileService.saveFile(this.signUp.id, formData).pipe(
          finalize(() => this.reset())
        );

        this.uploadSub = upload$.subscribe(event => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadProgress = Math.round(100 * (event.loaded / event.total));
            console.log(`File is ${this.uploadProgress}% uploaded.`);
          }
          if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            uploadedFile.fileName = event.body["generatedName"];
            this.uploadedFile.emit(uploadedFile)
            this.documents.push(uploadedFile);

            this.documentsInProgress = this.documentsInProgress.filter(item => item.fileName === file.name)

            this.documentsInProgressName = this.documentsInProgressName.filter(item => item !== file.name)
            this.uploadFileInProgress.emit(this.documentsInProgressName);
          }
        })
      }
    })
  }

  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }

  public onRemove($event, uploadedDocument: UploadedDocument): void {
    $event.preventDefault();

    this.fileService.removeFile(this.signUp.id, uploadedDocument.fileName).subscribe(() => {
      this.documents = this.documents.filter(item => item.fileName !== uploadedDocument.fileName);
      this.uploadedFile.emit(uploadedDocument)
    });
  }
}
