export class SentFile {
    signUpId: string;
    filename: string;
    malwareDetected: boolean;
    malwareName: string;
    encodedFile: string;
    uploaded: boolean;
    fileType: string;
    varName?: string;
}

export enum UploadedFileType {
  onderzoeksgegevens = "onderzoeksgegevens",
  opp = "opp",
  ouderlijkgezag = "ouderlijkgezag",
  aanvullend = "aanvullend",
  custom = "custom"
}


export enum UploadedFileTypeVarName {
    additionalReport = UploadedFileType.onderzoeksgegevens,
    additionalOpp = UploadedFileType.opp,
    additionalAuthority = UploadedFileType.ouderlijkgezag,
    additionalDocument = UploadedFileType.aanvullend,
    customDocumentQuestionField = UploadedFileType.custom
}
