import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AutoCompletor, FormField, Uploader } from "../../model/formField";
import {SignUp} from "../../model/signup";


@Component({
  selector: 'app-dynamic-formfield',
  templateUrl: './dynamic-formfield.component.html',
  styleUrls: ['./dynamic-formfield.component.css']
})
export class DynamicFormfieldComponent {
  @Input() field!: FormField;
  @Input() form!: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() checking: boolean;
  @Input() signUp: SignUp;
  @Input() keyupFn: () => void;
  @Input() changeFn: () => void;
  @Input() uploader: Uploader;
  @Input() autoCompletor: AutoCompletor;
  @Output() changedField = new EventEmitter<FormField>();
  @Output() uploadedFile = new EventEmitter<any>();
  @Output() uploadFileInProgress = new EventEmitter<string[]>();
  @Input() documentsInProgressName: string[];

  autocompleteCheck = false;
  autocompleteSelected = false;
  autocompleteInput = false;

  acceptedFiles: string = ".doc," +
    ".docx" +
    ",application/msword," +
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" +
    "image/png, " +
    "image/jpeg, " +
    "image/bmp," +
    "application/pdf," +
    "text/plain," +
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet," +
    "application/vnd.ms-spreadsheetml," +
    "application/vnd.ms-powerpoint";

  get isValid() {
    if (this.form.controls[this.field.varName]) {
      return this.form.controls[this.field.varName].valid;
    }
    return false;
  }

  get fieldValue() {
    return this.form.controls[this.field.varName]?.value;
  }

  get isTouched() {
    if (this.form.controls[this.field.varName]) {
      return this.form.controls[this.field.varName].touched;
    }
    return false;
  }

  get isDirty() {
    if (this.form.controls[this.field.varName]) {
      return this.form.controls[this.field.varName].dirty;
    }
    return false;
  }

  get schoolNameAutocomplete() {
    if (this.autocompleteSelected || this.autocompleteInput) {
      return false;
    }
    if (this.field && this.field.varName) {
      if(!this.form.controls[this.field.varName]
        || !this.form.controls[this.field.varName].value
        || this.form.controls[this.field.varName].value.length <= 2
        || (this.form.controls['schoolType'] && this.form.controls['schoolType'].value === 'buitenland')) {
        return false;
      }
    }
    return this.isDirty && !this.hasValue;
  }
  get errors() {
    if (this.form.controls[this.field.varName]) {
      return this.form.controls[this.field.varName].errors;
    }
    return false;
  }

  get hasValue() {
    if (this.autoCompletor?.data?.length > 0) {
      let value = this.form.controls[this.field.varName].value;

      for (let i = 0;i< this.autoCompletor.data.length;i++) {
        if (this.autoCompletor.data[i].locationName == value) return true;
      }
      return false;
    }
    return false;
  }
  get showError() {
    return (this.isSubmitted || this.isTouched || this.isDirty) && !this.isValid && this.errors;
  }

  public getRequiredPostfix() {
    return this.field.required ? '*' : '';
  }

  public changeCallBack(): void {
    if (this.changeFn) {
      this.changeFn();
    }
   this.checkRulesOnChange()
  }

  checkRulesOnChange = (): void => {
    this.changedField.emit(this.field)
  }

  public onBlur(): void {
    this.autocompleteInput = false;
    if (this.schoolNameAutocomplete) {
      this.autoCompleteFallBack();
    }
  }
  public keyupCallBack(): void {
    if (this.keyupFn) {
      this.keyupFn();
    }
  }

  public inputFn(): void {
    this.autocompleteSelected = false;
    this.autocompleteInput = true;
    this.autoCompletor.inputFn();
  }

  public onSelect($event): void {
    this.autocompleteSelected = true;
    if (this.autoCompletor && this.autoCompletor.onSelectFn) {
      this.autocompleteCheck = false;
      this.autoCompletor.onSelectFn($event,this.autoCompletor.parent);
    }
  }

  public autoCompleteFallBack(): void {
    this.autocompleteCheck = true;
    this.autoCompletor.fallBackFn();
  }
  public setAutocompleteValue(item: any): void {
    this.form.controls[this.field.varName].setValue(item.locationName);
    let event = { "item" : item};
    this.onSelect(event);
  }

  public checkRequiredUploads(uploadedFile) {
    this.uploadedFile.emit(uploadedFile)
  }

  public uploadInProgress(inProgressFiles: string[]) {
    this.uploadFileInProgress.emit(inProgressFiles);
  }
}
