<div [formGroup]="form">
  <input  type="file"
          [class.is-invalid]="showError"
          class="form-control-file"
          [formControlName]="field?.varName"
          [id]="field?.varName"
          [accept]="acceptedFiles"
          multiple="multiple"
          (change)="onUpload($event)"
  />
  <div class="form-row" *ngIf="getFiles().length !== 0 || documentsInProgress.length !== 0">
    <div class="col-sm-10 form-group" style="margin-top: 10px;">
      <ul>
        <li *ngFor="let item of getFiles(); let i = index" [ngClass]="{'alert-danger' : item.malwareDetected}">
          <span>{{item.originalFileName}}</span>
          <span><a href="#" (click)="onRemove($event,item)" *ngIf="!checking">Verwijderen</a></span>
          <div *ngIf="item.malwareDetected">{{item.malwareName}} aangetroffen</div>
        </li>
        <li *ngFor="let item of documentsInProgress; let i = index" [ngClass]="{'alert-danger' : item.malwareDetected}">
          <span>{{item.name}}</span>
          <div *ngIf="item.malwareDetected">{{item.malwareName}} aangetroffen</div>
          <div class="progress" *ngIf="uploadProgress">
            <progressbar [animate]="false" [value]="uploadProgress" type="success"><b>{{uploadProgress}}%</b></progressbar>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
