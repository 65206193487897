<div [formGroup]="form">

  <label *ngIf="!field.hideLabel && field.label" [attr.for]="field.varName">{{field.label}} {{getRequiredPostfix()}}</label>

  <div [ngSwitch]="field.controlType">

    <legend *ngSwitchCase="'SUBTITLE'">{{field.label}}</legend>

    <div *ngSwitchCase="'INFORMATION'" [innerHTML]="field.label"></div>

    <input *ngSwitchCase="'TEXT'"
           [class.is-invalid]="showError"
           [placeholder]="field.placeHolder"
           class="form-control" [formControlName]="field.varName"
           autocomplete="off"
           [id]="field.varName" type="text" (keyup)="keyupCallBack()" (change)="changeCallBack()">

    <ng-container  *ngSwitchCase="'READONLY'">
      <input [formControlName]="field.varName" type="hidden" />
      <div class="readonly">{{fieldValue}}</div>
    </ng-container>

    <input *ngSwitchCase="'HIDDEN'"
           [formControlName]="field.varName" type="hidden"
    />

    <ng-container  *ngSwitchCase="'READONLY_OR_HIDDEN'">
      <input [formControlName]="field.varName" type="hidden" />
      <ng-container *ngIf="(field.options.length > 1 || field.options$ !== undefined)">
        <div class="readonly">{{fieldValue}}</div>
      </ng-container>
    </ng-container>

    <input *ngSwitchCase="'TIMESTAMP'"
           [class.is-invalid]="showError"
           [placeholder]="field.placeHolder"
           class="form-control" [formControlName]="field.varName"
           [id]="field.varName" type="text" readonly="true">

    <ng-container *ngSwitchCase="'MULTIPLE_UPLOAD'">
      <app-uploader-component *ngIf="field"
        [showError]="showError"
        [field]="field"
        [form]="form"
        [signUp]="signUp"
        [acceptedFiles]="acceptedFiles"
        [checking]="checking"
        [documentsInProgressName]="documentsInProgressName"
        (uploadedFile)="checkRequiredUploads($event)"
        (uploadFileInProgress)="uploadInProgress($event)"
      ></app-uploader-component>
    </ng-container>

    <ng-container *ngSwitchCase="'AUTOCOMPLETE'">
      <input *ngSwitchCase="'AUTOCOMPLETE'" class="form-control" [formControlName]="field.varName"
             [id]="field.varName" type="text"
             [class.is-invalid]="showError"
             (input)="inputFn()"
             (blur)="onBlur()"
             [typeahead]="autoCompletor.data"
             [typeaheadScrollable]="true"
             [typeaheadOptionsInScrollableView]="15"
             typeaheadOptionField="searchField"
             [typeaheadMinLength]="0"
             [typeaheadItemTemplate]="duoSchoolItemTemplate"
             (typeaheadOnSelect)="onSelect($event)"
      >
      <ng-template #duoSchoolItemTemplate let-model="item" let-index="index">
        {{model.displayName}} <br />
        <ng-container *ngIf="model.alias"><i>{{model.alias}}</i><br /></ng-container>
        {{model.streetName}} {{model.houseNumber}} {{model.cityName}}
      </ng-template>
      <div *ngIf="schoolNameAutocomplete" class="errorMessage">Kies een school uit onderstaande lijst.</div>
        <div *ngIf="autocompleteCheck && schoolNameAutocomplete" class="schoollist">
          <div class="school" *ngFor="let model of autoCompletor.data" (click)="setAutocompleteValue(model)">
            {{model.displayName}} <br />
            <ng-container *ngIf="model.alias"><i>{{model.alias}}</i><br /></ng-container>
            {{model.streetName}} {{model.houseNumber}} {{model.cityName}}
          </div>
          <div *ngIf="autoCompletor.data?.length === 0"><i>geen resultaten gevonden</i></div>
        </div>
    </ng-container>

    <textarea *ngSwitchCase="'TEXTAREA'"
              [class.is-invalid]="showError"
              class="form-control" [formControlName]="field.varName"
              [id]="field.varName" (keyup)="keyupCallBack()" (change)="changeCallBack()"></textarea>

    <ng-container *ngSwitchCase="'CHECKBOX'">
      <ng-container *ngFor="let option of field.options">
        <div class="form-check">
          <input  class="form-check-input" [formControlName]="field.varName" (change)="changeCallBack()"
                  [class.is-invalid]="showError"
                  [id]="field.varName" type="checkbox" [value]="option.value">
          <label class="form-check-label" [for]="field.varName">{{option.text}} {{getRequiredPostfix()}}</label>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'SELECT'">
      <select [id]="field.varName" [formControlName]="field.varName"
              [class.is-invalid]="showError"
              class="form-control" (change)="changeCallBack()">
        <option selected disabled value=""></option>
        <ng-container   *ngIf="field.options?.length">
          <option *ngFor="let opt of field.options" [value]="opt.value">{{opt.text}}</option>
        </ng-container>
        <ng-container   *ngIf="!field.options || !field.options.length">
          <option *ngFor="let opt of field.options$ | async" [value]="opt.value">{{opt.text}}</option>
        </ng-container>
      </select>
    </ng-container>

    <ng-container *ngSwitchCase="'RADIO'">
      <ng-container   *ngIf="field.options?.length">
        <div class="form-check form-check-inline" *ngFor="let opt of field.options">
          <input type="radio" class="form-check-input" [value]="opt.value" [formControlName]="field.varName" (change)="changeCallBack()" />
          <label class="form-check-label">{{opt.text}}</label>
        </div>
      </ng-container>
      <ng-container   *ngIf="!field.options || !field.options.length">
        <div class="form-check form-check-inline"  *ngFor="let opt of field.options$ | async">
          <input type="radio" [value]="opt.value" [formControlName]="field.varName" />
          <label class="form-check-label">{{opt.text}}</label>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'SELECT_OR_HIDDEN'">
      <ng-container *ngIf="(field.options.length === 1 || field.options$ === undefined)">
        <input type="hidden" [formControlName]="field.varName" />
      </ng-container>
      <ng-container *ngIf="(field.options.length > 1 || field.options$ !== undefined)">
        <select [id]="field.varName" [formControlName]="field.varName"
                [class.is-invalid]="showError"
                class="form-control" (change)="changeCallBack()">
          <option selected disabled value=""></option>
          <ng-container   *ngIf="field.options?.length">
            <option *ngFor="let opt of field.options" [value]="opt.value">{{opt.text}}</option>
          </ng-container>
          <ng-container   *ngIf="!field.options || !field.options.length">
            <option *ngFor="let opt of field.options$ | async" [value]="opt.value">{{opt.text}}</option>
          </ng-container>
        </select>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="false" class="alert alert-success">
    isSubmitted : {{isSubmitted}}<br />
    isTouched : {{isTouched}}<br />
    isDirty : {{isDirty}}<br />
    isValid : {{isValid}}<br />
    errors : {{errors}}<br />
  </div>

  <ng-container *ngIf="(isSubmitted || isTouched || isDirty) && !isValid && errors">
    <div class="errorMessage" *ngIf="field.errorMessage">{{field.errorMessage}}</div>
    <ng-container *ngIf="!field.errorMessage">
      <div class="errorMessage" *ngIf="errors['missingFiles']">Er ontbreken bestanden</div>
      <div class="errorMessage" *ngIf="errors['required']">{{field.label}} is verplicht</div>
      <div class="errorMessage" *ngIf="errors['pattern']">Ongeldig emailadres</div>
      <div class="errorMessage" *ngIf="errors['email']">Ongeldig emailadres</div>
      <div class="errorMessage" *ngIf="errors['appCheckEmail']">Ongeldig emailadres</div>
      <div class="errorMessage" *ngIf="errors['appCheckBSN']">Burgerservicenummer is ongeldig</div>
      <div class="errorMessage" *ngIf="errors['appCheckOT']">Deze aanmeldcode lijkt niet correct, controleer en voeg opnieuw in of vraag de basisschool. Uw inschrijfformulier is opgeslagen en kan later worden voltooid.</div>
      <div class="errorMessage" *ngIf="errors['appCheckOWN']">Onderwijsnummer is ongeldig</div>
      <div class="errorMessage" *ngIf="errors['appCheckVnum']">Vreemdelingennummer is ongeldig</div>
      <div class="errorMessage" *ngIf="errors['invalidPostalCode']">Postcode is ongeldig</div>
      <div class="errorMessage" *ngIf="errors['appHouseNumber']">Huisnummer is ongeldig</div>
      <div class="errorMessage" *ngIf="errors['maxlength']">Maximaal {{errors['maxlength'].requiredLength}} tekens</div>
      <div class="errorMessage" *ngIf="errors['appCheckPhone']">Ongeldig formaat, gebruik alleen cijfers</div>
      <div class="errorMessage" *ngIf="errors['appConfirmCheck']">U dient akkoord te gaan</div>
      <div class="errorMessage" *ngIf="errors['invalidIban']">IBAN is ongeldig</div>
      <div class="errorMessage" *ngIf="errors['appDateCheck']">Ongeldige datum, gebruik formaat dd-mm-jjjj</div>
      <div class="errorMessage" *ngIf="errors['appClusterDate']">Ongeldige clusterdatum, gebruik formaat dd-mm-jjjj en datum in de toekomst</div>
      <div class="errorMessage" *ngIf="errors['appFutureDate']">Ongeldige datum, gebruik formaat dd-mm-jjjj en datum in de toekomst</div>
      <div class="errorMessage" *ngIf="errors['appFutureDateInflux']">Ongeldige datum, gebruik formaat dd-mm-jjjj en datum moet binnen het gekozen schooljaar, en in de toekomst liggen</div>
      <div class="errorMessage" *ngIf="errors['appCheckInitials']">U lijkt een voornaam in te vullen, geef alleen uw voorletters, zonder spatie. Bijvoorbeeld P.J.</div>
    </ng-container>
  </ng-container>

  <small
    *ngIf="field.infoText &&
      (field.varName !== 'schoolLocation' || (field.varName === 'schoolLocation' && field.options.length > 1)) &&
      (field.varName !== 'educationType' || (field.varName === 'educationType' && field.options.length > 1)) &&
      (form.controls['schoolType'] === undefined || form.controls['schoolType'].value !== 'buitenland')"
    class="form-text text-muted"
  >
    {{field.infoText}}
  </small>

</div>
