import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {ColorService} from "./shared/services/color.service";
import {SchoolService} from "./shared/services/school.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(public router: Router,
              public colorService: ColorService,
              public schoolService: SchoolService) { }

  ngOnInit() {
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.changeStylingOnNavigate(event.url);
        }
      });
  }

  onActivate(event) {
    const scrollToTop = window.setInterval(() => {
        const pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
  }

  changeStylingOnNavigate(url: string) {
    const commonStylingUrls: string[] = [
      '/home',
      '/faq',
      '/privacy',
      '/contact',
    ]
    if (commonStylingUrls.indexOf(url) !== -1 || this.schoolService.getSchool() === undefined) {
      this.colorService.changeColor('common');
    } else {
      this.colorService.changeColor(this.schoolService.getSchool().stylingId);
    }
  }

  /**
   * should we show the steps? We only show the steps while in a form
   */
  shouldShowSteps() {
    return this.router.url !== '/' &&
      !this.router.url.includes('/home') &&
      !this.router.url.includes('/faq') &&
      !this.router.url.includes('/privacy') &&
      !this.router.url.includes('/contact') &&
      !this.router.url.includes('/bedankt') &&
      !this.router.url.includes('/login') &&
      !this.router.url.includes('/logout') &&
    !this.router.url.includes('/dl');
  }
}
