import {AdditionalInformation, SignUp} from '../../shared/model/signup';
import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SignUpService} from '../../shared/services/signup.service';
import {SentFile} from '../../shared/model/sentfile';
import {FormService} from "../../shared/services/form.service";
import {DynamicSectionComponent} from "../../shared/abstract/dynamic-section-component";
import {FormType, SectionType} from "../../shared/model/formSection";
import {FormBuilder} from "@angular/forms";
import {ConditionType, ControlType, FormField} from "../../shared/model/formField";
import {NotificationService} from "../../shared/services/notification.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";

interface CustomOnFiles {[key: string]: SentFile[]}

@Component({
  selector: 'app-additional-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class AdditionalDynamicComponent extends DynamicSectionComponent implements OnInit {
  @Input() checking: boolean = false;
  signUp: SignUp;
  additionalInformation: AdditionalInformation = {
    sendByPostService: false,
    documents: [],
    customQuestionField: {},
    customDocumentQuestionField: {}
  } as AdditionalInformation;

  customDocuments: {[key: string]: SentFile[]};

  researchInvalid = false;
  oppInvalid = false;
  customInvalid = false;
  authorityInvalid = false;

  constructor(router: Router,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              private formBuilder: FormBuilder,
              formService: FormService,
              private signUpService: SignUpService,
              modalService: BsModalService) {
    super(router,formService,notificationService,modalService, ruleEvaluatorService, SectionType.ADDITIONAL, FormType.SIGNUP)
    this.formGroup = this.formBuilder.group({});
    this.signUp = this.signUpService.getSignUp();

    if (this.signUp.additionalInformation !== undefined && this.signUp.additionalInformation !== null) {
      this.additionalInformation = this.signUp.additionalInformation;
      this.initCustomQuestions(this.additionalInformation);
      this.initCustomDocumentQuestions(this.additionalInformation);
    }
  }

  ngOnInit() {
    super.commonNgOnInit();
    if (!this.getSection()) return;

    super.commonNgOnInit();
    if (!this.getSection()) return;
    this.getSection().fields.forEach(fld => {
      this.addCallBackFunction(fld)
      if (fld.varName === 'sendByPostService') {
      }
      this.checkControl(fld,this.additionalInformation, this.signUp);
    });
  }

  addCallBackFunction(fld: FormField): void {
    switch (fld.varName) {
      case 'sendByPostService':
        fld.changeFn = this.onPostServiceChange;
        break;
    }
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    fld.condition.forEach(c => {
      switch (c) {
        case ConditionType.ATTACHMENTS:
          if (!(this.signUp.particularities?.hasOpp || this.signUp.particularities?.hasMedicalDiagnosis || this.signUp.family.parentalAuthority != 'beide')) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.NOT_SEND_BY_POST:
          if (this.additionalInformation.sendByPostService.toString() == 'true') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.PARENTAL_AUTHORITY_NOT_BOTH:
          if (this.signUp.family.parentalAuthority.toLowerCase() == 'beide'
            || this.signUp.family.parentalAuthority == ''
            || (!this.signUp.careTakers.secondCareTaker && this.signUp.family.parentalAuthority.toLowerCase() != 'anders')
          ) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.MEDICAL_DIAGNOSIS:
          if (this.signUp.particularities?.hasMedicalDiagnosis.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.HAS_OPP:
          if (this.signUp.particularities?.hasOpp.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
      }
    })
    return checkOk;
  }

  onPostServiceChange = (): void => {
    this.additionalInformation.sendByPostService = this.getFormValueByVarName('sendByPostService')
    this.getSection().fields.forEach(fld => {
      if (fld.controlType === ControlType.MULTIPLE_UPLOAD) {
        this.checkControl(fld,this.additionalInformation, this.signUp);
      }
    });
  }

  /**
   * Wordt gebruikt door emit van dynamic formfield.
   * @param uploadedDocument
   */
  uploadDocument(uploadedDocument) {
      // Voeg het document toe aan signup
      if (this.signUp.additionalInformation.documents.filter((item) => item.fileName === uploadedDocument.fileName).length == 0) {
          this.signUp.additionalInformation.documents.push(uploadedDocument);
      } else {
        // Verwijder het document uit signup
        this.signUp.additionalInformation.documents = this.signUp.additionalInformation.documents.filter((item) => item.fileName !== uploadedDocument.fileName);
      }

      this.checkRequiredUploads();
  }

  checkRequiredUploads() {
    this.getSection().fields.forEach(fld => {
      if (fld.required) {
        if (this.signUp.additionalInformation.documents.filter((item) => item.varName === fld.varName).length == 0) {
            this.addMissingFilesError(fld);
        } else {
            this.clearMissingFilesError(fld);
        }
      }
    });
  }

  clearMissingFilesError(fld: FormField) {
    if (this.formGroup.controls[fld.varName]) {
      this.formGroup.controls[fld.varName].setErrors(null);
    }
  }
  addMissingFilesError(fld: FormField) {
    if (this.formGroup.controls[fld.varName]) {
      this.formGroup.controls[fld.varName]?.setErrors({'missingFiles':true});
    }
  }

  save(): boolean {
    this.checkRequiredUploads();
    if (!this.prepareSave(this.additionalInformation)) return false;
    this.prepareCustomQuestionsForSave(this.additionalInformation);

    if ((this.oppInvalid || this.researchInvalid || this.authorityInvalid || this.customInvalid) && !this.additionalInformation.sendByPostService) {
      this.changeButtonText();
      return false;
    }

    this.signUpService.saveAdditional(this.additionalInformation).subscribe(() => {
      this.goToNext(this.signUp);
    }, (err) => {
      this.getRouter().navigate(['/home']);
      this.handleSaveError(err);
    });

    return true;
  }

  getVirusWarning(): string {
    if (this.signUp.malwareDetected) {
      return "LET OP: in ten minste één van de door u geüploade bestanden is een virus of malware gedetecteerd. De getroffen bestand(en) zijn hieronder in het rood aangegeven. Deze worden niet door ons verwerkt, u kunt de aanmelding wel afronden.";
    }
    return super.getVirusWarning();
  }
}
